<template>
  <!-- TODO: Rename/Replace this component to `ProgressBar.vue` during the next UI revamp -->
  <div class="pb__wrapper">
    <div class="pb__container">
      <span
        class="pb__milestone pb__milestone--completed pb__milestone--major"
        :style="`height: ${completionPercentage}%`"
      >
        <span class="pb__milestone__shape pb__milestone__tooltip">
          <span class="pb__milestone__tooltip-content"
            >{{ Math.round(completionPercentage) }}%</span
          ></span
        ></span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    completionPercentage: {
      type: Number,
      default: 36
    }
  }
};
</script>
<style lang="scss" scoped>
// colors
$default-color: #ccd9ee;

// sizes
$border-radius: 5px;
$progress-bar-size: 9px;
$major-milestone-size: 20px;

// Borrowed styles from `ProgressBar.vue`

.pb__wrapper {
  height: 100%;
  .pb__container {
    height: inherit;
    width: $progress-bar-size;
    border-radius: $border-radius;
    background: $default-color;

    .pb__milestone {
      border-radius: $border-radius;
      display: block;
      transition: height 0.5s;
      position: relative;

      &.pb__milestone--major {
        padding: 0 0 20px;
        .pb__milestone__shape {
          width: $major-milestone-size;
          border-radius: $major-milestone-size;
          background: #ffffff;
          height: $major-milestone-size;
          border: 3px solid $brand-primary;
          left: 50%;
          margin-left: -($major-milestone-size/2);
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
          position: absolute;
          bottom: 0;
        }
      }

      /* different states */

      &.pb__milestone--completed {
        background: $brand-primary;
      }

      /* tooltip */
      .pb__milestone__tooltip {
        cursor: pointer;
        position: relative;

        .pb__milestone__tooltip-content {
          display: none;
          position: absolute;
          left: 24px;
          top: -11px;

          color: #ffffff;

          width: 46px;
          height: 36px;
          padding: 10px 5px 10px 10px;

          font-weight: 500;
          font-size: 12px;
          line-height: 16px;

          background: url("~@/assets/images/tool-tip.svg") no-repeat;
        }

        &:hover {
          .pb__milestone__tooltip-content {
            display: block;
          }
        }
      }
    }
  }
}
</style>
